import { gql } from "~/__generated__"

export const ADMIN_USER_FRAGMENT = gql(/* GraphQL */ `
  fragment AdminUserEntry on User {
    id
    email
    firstName
    lastName
    jobTitle
    isOnboarded
    isAdmin
    influencerCount
    loginCount
    lastActivityAt
    notifyOfNewAgencies
    notifyOfNewCastingCalls
    agencyRole
    agency {
      id
      name
      agentName
      contactEmail
      phone
      website
      logoThumbnailUrl
      businessType
      businessRelationship
    }
    activeSubscription {
      id
      tier {
        id
        name
      }
    }
  }
`)

export const ADMIN_USERS_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query AdminUsers(
    $filters: UsersFiltersInput
    $sortField: UserSortFieldEnum
    $sortDirection: SortDirectionEnum
    $first: Int
    $usersCursor: String
  ) {
    users(
      filters: $filters
      sortField: $sortField
      sortDirection: $sortDirection
      first: $first
      after: $usersCursor
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          ...AdminUserEntry
        }
      }
    }
  }
`)

export const ADMIN_INVITE_USER_MUTATION = gql(`
  mutation AdminInviteUser($input: UserInviteInput!) {
    userInvite(input: $input) {
      user {
        id
        email
      }
    }
  }
`)

export const ADMIN_CREATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation CreateUser($input: ManualUserCreateInput!) {
    manualUserCreate(input: $input) {
      user {
        id
        email
        firstName
        lastName
        jobTitle
        agency {
          name
          agentName
          contactEmail
          phone
          website
          logoThumbnailUrl
        }
      }
    }
  }
`)

export const ADMIN_RESEND_USER_INVITE_MUTATION = gql(/* GraphQL */ `
  mutation ResendUserInvite($input: ResendUserInviteInput!) {
    resendUserInvite(input: $input) {
      user {
        id
        email
      }
    }
  }
`)

export const ADMIN_UPDATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation AdminUserUpdateUser($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...AdminUserEntry
      }
    }
  }
`)

export const ADMIN_IMPERSONATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation ImpersonateUser($input: ImpersonateUserInput!) {
    impersonateUser(input: $input) {
      success
      errors
    }
  }
`)
