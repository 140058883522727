import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { BusinessRelationshipEnum } from "~/__generated__/graphql"
import { settingsAccountPath } from "~/common/paths"
import { SubscriptionPlanSelect } from "~/screens/onboarding/choose-plan/components/SubscriptionPlanSelect"
import { Button } from "~/shadcn/ui/button"
import { ModalDialog } from "~/ui/ModalDialog"
import Text from "~/ui/typography"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireAgencyNotFree = () => {
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (result.loading) return
    if (currentUser?.isAdmin) return
    if (
      currentUser?.isOnboarded &&
      currentUser?.agency?.businessRelationship !==
        BusinessRelationshipEnum.Free
    )
      return

    setShowModal(true)
  }, [currentUser, result.loading, navigate])

  if (result.loading) {
    return null
  }

  return (
    <>
      <Outlet />
      <ModalDialog
        open={showModal}
        onClose={() => {}}
        className="ml-[290px] sm:mt-[90px] sm:h-[85vh]"
      >
        <div className="max-w-[1000px] mx-auto flex flex-col">
          <Text variant="header2" className="text-center">
            Thank You for Being an Early Zano User! 🚀
          </Text>
          <Text className="mt-3 text-center">
            As an early user, your agency details and roster will remain visible
            for discovery, ensuring potential opportunities. Choose a plan below
            to re-gain full access to platform features such as media kits,
            casting calls and managing your roster.
          </Text>
          <Text className="mt-5 mb-8 text-center">
            Have questions? We’re here to help! 💙 Email us at{" "}
            <a href="mailto:support@zanoplatform.com">
              support@zanoplatform.com
            </a>
          </Text>
          <SubscriptionPlanSelect />
          <div className="text-center mt-5 text-xs">
            <Text className="text-defaultSecondary">
              Need to manage your account or notification settings? Go to the{" "}
              <Button
                variant="linkSecondary"
                size="none"
                onClick={() => navigate(settingsAccountPath({}))}
              >
                Account Page
              </Button>
              .
            </Text>
          </div>
        </div>
      </ModalDialog>
    </>
  )
}
