import { useMemo, useState } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  BusinessTypeEnum,
  SubscriptionBillingCycleEnum,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { cn } from "~/common/shadcn-utils"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useSubscriptionTiers } from "~/common/useSubscriptionTiers"
import { displayErrors } from "~/common/validations"
import check from "~/images/icons/check"
import ex from "~/images/icons/ex"
import { Button } from "~/shadcn/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/shadcn/ui/card"
import { moneyDisplay } from "~/util/numbers"
import { BillingCycleToggle } from "./BillingCycleToggle"

const CREATE_CHECKOUT_SESSION = gql(`
  mutation CreateCheckoutSession($stripePriceId: String!) {
    checkoutSessionCreate(input: { stripePriceId: $stripePriceId }) {
      sessionUrl
    }
  }
`)

const Checked = () => <img {...check} alt="Checked" />
const Unchecked = () => <img {...ex} alt="Unchecked" />

const displayPrice = (tier: {
  priceCents: number
  billingCycle: SubscriptionBillingCycleEnum
}) => {
  const divisor =
    tier.billingCycle === SubscriptionBillingCycleEnum.Monthly ? 1 : 12

  return `${moneyDisplay(tier.priceCents / divisor / 100)} / month`
}

export const SubscriptionPlanSelect = () => {
  const currentUser = useCurrentUser()
  const [billingCycle, setBillingCycle] = useState(
    SubscriptionBillingCycleEnum.Monthly
  )
  const { subscriptionTiers, loading, error } = useSubscriptionTiers(
    currentUser.agency?.businessType || BusinessTypeEnum.Talent
  )
  const filteredSubscriptionTiers = useMemo(() => {
    return subscriptionTiers.filter(
      (tier) => tier.billingCycle === billingCycle
    )
  }, [subscriptionTiers, billingCycle])

  const [createCheckoutSession] = useSafeMutation(CREATE_CHECKOUT_SESSION)

  const handlePlanSelection = async (stripePriceId: string) => {
    const result = await createCheckoutSession({
      variables: { stripePriceId },
    })

    if (result.errors) {
      console.error("Errors creating checkout session:", result.errors)
      displayErrors(result.errors)
      return
    }

    invariant(result.data, "Expected data from createCheckoutSession")
    window.location.href = result.data.checkoutSessionCreate.sessionUrl
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <>
      <BillingCycleToggle
        billingCycle={billingCycle}
        setBillingCycle={setBillingCycle}
      />
      <div
        className={cn("grid grid-cols-1 md:grid-cols-3 gap-6", {
          "md:grid-cols-4": filteredSubscriptionTiers.length > 3,
        })}
      >
        {filteredSubscriptionTiers.map((product) => (
          <Card key={product.id} className="flex flex-1 flex-col">
            <CardHeader>
              <CardTitle>{product.displayName || product.name}</CardTitle>
              <CardDescription>{product.tagline}</CardDescription>
              <CardDescription>{displayPrice(product)}</CardDescription>
            </CardHeader>
            <CardContent className="flex-1 flex flex-col">
              <ul className="mb-4">
                {!currentUser.hasBeenSubscribed && (
                  <li className="flex items-center mb-2 text-xs">
                    <Checked />
                    <span className="ml-2">1 month FREE trial</span>
                  </li>
                )}
                {product.features.map((feature, index) => (
                  <li key={index} className="flex items-center mb-2 text-xs">
                    {feature.included ? <Checked /> : <Unchecked />}
                    <span
                      className="ml-2"
                      dangerouslySetInnerHTML={{ __html: feature.description }}
                    />
                  </li>
                ))}
              </ul>
              <Button
                className="w-full mt-auto"
                onClick={() => handlePlanSelection(product.stripePriceId)}
              >
                Go {product.name}
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  )
}
