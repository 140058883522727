import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Button } from "~/shadcn/ui/button"
import { Form } from "~/shadcn/ui/form"
import { SearchValues, searchDefaultValues, searchResolver } from "../schema"
import { SearchFilter } from "./SearchFilter"

export const SearchBar = ({
  setSearchValues,
  initialValues,
  showAgencyFilter = false,
}: {
  setSearchValues: (values: SearchValues) => void
  initialValues?: SearchValues
  showAgencyFilter?: boolean
}) => {
  const form = useForm<SearchValues>({
    resolver: searchResolver,
    defaultValues: searchDefaultValues,
  })

  const applySearch = useCallback(
    (values: SearchValues) => {
      setSearchValues(values)
    },
    [setSearchValues]
  )

  const onClear = () => {
    form.reset(searchDefaultValues)
    applySearch({})
  }

  const onSubmit = form.handleSubmit(applySearch, (error) => {
    console.error(error)
  })

  useEffect(() => {
    if (initialValues) {
      form.reset({ ...searchDefaultValues, ...initialValues })
      applySearch({ ...searchDefaultValues, ...initialValues })
    }
  }, [form, applySearch, initialValues])

  return (
    <Form {...form}>
      <div className="flex-grow flex flex-row justify-stretch gap-4 max-w-5xl">
        <div className="basis-2/5">
          <SearchFilter onChange={onSubmit} />
        </div>

        <Button
          type="button"
          variant="linkSecondary"
          size="none"
          onClick={onClear}
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}
