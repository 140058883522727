import { useQuery } from "@apollo/client"
import { useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { InfluencerSortFieldEnum } from "~/__generated__/graphql"
import {
  InstagramIcon,
  TikTokIcon,
  TwitchIcon,
  YouTubeIcon,
} from "~/common/SocialMediaIcons"
import { categoryName, exclusivityName } from "~/common/enums"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { LoadingIndicator } from "~/components/LoadingIndicator"
import { PageVisibilityProvider } from "~/contexts/PageVisibilityContext"
import ZanoLogo from "~/images/zano_logo_full.svg?react"
import { AGENCY_FRAGMENT } from "~/screens/agencies/api"
import { ViewInfluencerScreen } from "~/screens/influencers/view-influencer/ViewInfluencerScreen"
import { Card, CardContent } from "~/shadcn/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { Avatar } from "~/ui/Avatar"
import { CopyButton } from "~/ui/CopyButton"
import { ErrorBox } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { ModalDialog } from "~/ui/ModalDialog"
import { friendlyNumber } from "~/util/numbers"
import { AGENCY_QUERY } from "../api"
import { InfluencerSortProvider } from "../components/InfluencerSortContext"
import { SearchBar } from "../components/SearchBar"
import { SortableColumn } from "../components/SortableColumn"
import { useInfluencers } from "../useInfluencers"
import { AgencyHeader } from "./components/AgencyHeader"

export const PublicRosterScreen = () => {
  const { agencyId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeInfluencerId = searchParams.get("influencerId")

  const {
    data: agencyData,
    error: agencyError,
    loading: agencyLoading,
  } = useQuery(AGENCY_QUERY, {
    variables: { agencyId: agencyId ?? "" },
  })
  const agency = getFragmentData(AGENCY_FRAGMENT, agencyData?.agency)
  useDocumentTitle(agency ? agency.name : "Roster")

  const {
    influencers,
    data: influencerData,
    error,
    loading,
    setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo,
  } = useInfluencers({ filters: { agencyIds: [agencyId ?? ""] } })

  if (agencyLoading)
    return (
      <div className="flex flex-1 items-center justify-center py-8">
        <LoadingIndicator />
      </div>
    )

  return (
    <div className="h-full pb-4 flex flex-col">
      {agencyError || !agency ? (
        <div className="my-8 flex-grow">
          <ErrorBox message={`Agency Not Found`} />
        </div>
      ) : (
        <>
          <PageVisibilityProvider visible="public">
            <AgencyHeader agency={agency} />
            <div className="flex-grow relative">
              <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
                <CardContent className="flex-1 p-6 pt-4 flex flex-col">
                  <SearchBar setSearchValues={setSearchValues} />
                  {!influencerData && loading ? (
                    <LoadingIndicatorCentered />
                  ) : error || !influencerData ? (
                    <ErrorBox message="Error loading influencers." />
                  ) : (
                    <Card className="absolute top-[70px] bottom-4 left-6 right-6 overflow-auto">
                      <Table className="">
                        <TableHeader>
                          <InfluencerSortProvider
                            sortField={sortField}
                            sortDir={sortDir}
                            onSort={onSort}
                          >
                            <TableRow>
                              <TableHead sticky>
                                <SortableColumn
                                  field={InfluencerSortFieldEnum.Name}
                                >
                                  Name
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={InfluencerSortFieldEnum.Category}
                                >
                                  Category
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={InfluencerSortFieldEnum.Country}
                                >
                                  Country
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={
                                    InfluencerSortFieldEnum.TikTokFollowers
                                  }
                                >
                                  <div className="flex gap-1 items-center">
                                    <TikTokIcon className="w-[18px] h-[20px]" />
                                    TT
                                  </div>
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={
                                    InfluencerSortFieldEnum.InstagramFollowers
                                  }
                                >
                                  <div className="flex gap-1 items-center">
                                    <InstagramIcon className="w-[18px] h-[18px]" />
                                    IG
                                  </div>
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={
                                    InfluencerSortFieldEnum.YoutubeSubscribers
                                  }
                                >
                                  <div className="flex gap-1 items-center">
                                    <YouTubeIcon className="w-[19px] h-[13px]" />
                                    YT
                                  </div>
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={
                                    InfluencerSortFieldEnum.TwitchSubscribers
                                  }
                                >
                                  <div className="flex gap-1 items-center">
                                    <TwitchIcon className="w-[17px] h-[18px]" />
                                    TW
                                  </div>
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={InfluencerSortFieldEnum.Exclusivity}
                                >
                                  Exclusivity
                                </SortableColumn>
                              </TableHead>
                              <TableHead sticky>
                                <SortableColumn
                                  field={InfluencerSortFieldEnum.AgentEmail}
                                >
                                  Contact Email
                                </SortableColumn>
                              </TableHead>
                            </TableRow>
                          </InfluencerSortProvider>
                        </TableHeader>
                        <TableBody>
                          {influencers
                            .filter((influencer) => !influencer.deleted)
                            .map((influencer) => (
                              <TableRow
                                key={influencer.id}
                                onClick={() => {
                                  invariant(
                                    influencer.id,
                                    "Influencer ID is required"
                                  )
                                  setSearchParams(
                                    (params) => {
                                      invariant(
                                        influencer.id,
                                        "Influencer ID is required"
                                      )
                                      params.set("influencerId", influencer.id)
                                      return params
                                    },
                                    { replace: true }
                                  )
                                }}
                                className="cursor-pointer"
                              >
                                <TableCell>
                                  <div className="flex flex-row items-center gap-2">
                                    <Avatar
                                      src={influencer.profileImageThumbnailUrl}
                                      alt={influencer.name}
                                    />
                                    {influencer.name}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {categoryName(influencer.category)}
                                </TableCell>
                                <TableCell>
                                  {influencer.countryData?.name}
                                </TableCell>
                                <TableCell>
                                  {friendlyNumber(
                                    influencer.tikTokProfile?.followers
                                  )}
                                </TableCell>
                                <TableCell>
                                  {friendlyNumber(
                                    influencer.instagramProfile?.followers
                                  )}
                                </TableCell>
                                <TableCell>
                                  {friendlyNumber(
                                    influencer.youtubeProfile?.subscribers
                                  )}
                                </TableCell>
                                <TableCell>
                                  {friendlyNumber(
                                    influencer.twitchProfile?.subscribers
                                  )}
                                </TableCell>
                                <TableCell>
                                  {exclusivityName(influencer.exclusivity)}
                                </TableCell>
                                <TableCell>
                                  {influencer.agentEmail && (
                                    <CopyButton
                                      textToCopy={influencer.agentEmail}
                                      successMessage={`"${influencer.agentEmail}" copied to clipboard`}
                                      label={influencer.agentEmail}
                                      className="w-full justify-between py-2"
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <InfiniteLoadMore
                        onEndReached={onLoadNextPage}
                        canLoadMore={!loading && !!pageInfo?.hasNextPage}
                        loadingText="Loading more..."
                        loading={loading && influencers.length > 0}
                        className="p-4"
                      />
                      {loading &&
                        influencerData.influencers.edges.length === 0 && (
                          <div className="p-4">
                            <LoadingIndicatorCentered />
                          </div>
                        )}
                    </Card>
                  )}
                </CardContent>
              </Card>
              <ModalDialog
                open={!!activeInfluencerId}
                onClose={() => {
                  setSearchParams(
                    (params) => {
                      params.delete("influencerId")
                      return params
                    },
                    { replace: true }
                  )
                }}
              >
                {activeInfluencerId && (
                  <ViewInfluencerScreen influencerId={activeInfluencerId} />
                )}
              </ModalDialog>
            </div>
          </PageVisibilityProvider>
        </>
      )}
      <div className="flex">
        <a
          href="https://zanoplatform.com"
          className="flex flex-row text-xs gap-1 items-center"
        >
          <span>Powered by</span> <ZanoLogo height={9} width={38} />
        </a>
      </div>
    </div>
  )
}
