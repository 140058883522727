import { useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useDebounce } from "use-debounce"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchValues } from "../schema"

import SearchIcon from "~/images/icons/search.svg?react"

const FIELD_NAME = "query"

export const SearchFilter = ({ onChange }: { onChange: () => void }) => {
  const [lastQuery, setLastQuery] = useState<string>("")
  const form = useFormContext<SearchValues>()

  const query = useWatch({ control: form.control, name: FIELD_NAME })
  const [debouncedQuery] = useDebounce(query, 500)

  useEffect(() => {
    const sanitized = debouncedQuery || ""
    if (sanitized !== lastQuery) {
      onChange()
      setLastQuery(sanitized)
    }
  }, [onChange, debouncedQuery, lastQuery, setLastQuery])

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={({ field }) => (
        <FormItem>
          <FloatingLabelInput
            {...field}
            id="query"
            label="Search"
            icon={<SearchIcon />}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
