import { Link, matchPath, useLocation } from "react-router-dom"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import {
  adminUsersPath,
  // castingCallsFavoritesPath,
  castingCallsMyCallsPath,
  castingCallsMySubmissionsPath,
  castingCallsPath,
  // brandAgenciesPath,
  dashboardPath,
  myRosterPath,
  rosterPath,
  settingsAccountPath,
  settingsSubscriptionPath,
  settingsTeamPath,
  talentAgenciesPath,
} from "~/common/paths"
import { cn } from "~/common/shadcn-utils"
import { Hr } from "~/ui/Hr"

import AccountIcon from "~/images/icons/account.svg?react"
import AdminUsersIcon from "~/images/icons/admin-users.svg?react"
import TeamIcon from "~/images/icons/team.svg?react"
// import BrandsIcon from "~/images/icons/brand.svg?react"
import { useMemo } from "react"
import CastingCallsIcon from "~/images/icons/casting.svg?react"
import DashboardIcon from "~/images/icons/dashboard.svg?react"
import MyRosterIcon from "~/images/icons/my-roster.svg?react"
import RosterIcon from "~/images/icons/roster.svg?react"
import SubscriptionIcon from "~/images/icons/subscription.svg?react"
import TalentAgenciesIcon from "~/images/icons/talent-agency.svg?react"
import ZanoLogo from "~/images/zano_logo_full.svg?react"

export const Sidebar = () => {
  const user = useCurrentUser()
  const location = useLocation()

  const isCastingCallsActive = useMemo(
    () => location.pathname.startsWith("/casting"),
    [location.pathname]
  )

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 border-r border-gray30">
      <div className="flex h-24 shrink-0 items-center">
        <ZanoLogo className="w-24 mx-auto" />
      </div>
      <nav className="flex flex-1 flex-col">
        <h3 className="text-gray50 mb-2 pl-3">MAIN MENU</h3>
        <ul className="flex flex-col gap-y-2">
          <li>
            <ul className="-mx-2 space-y-1">
              <NavItem
                to={dashboardPath.pattern}
                label="Dashboard"
                icon={<DashboardIcon />}
              />
              <NavItem
                to={rosterPath.pattern}
                label="Roster Database"
                icon={<RosterIcon />}
              />
              {user.canIndexAllCastingCalls.value ? (
                <>
                  {user.canIndexCastingCalls.value && (
                    <>
                      <NavItem
                        to={castingCallsPath.pattern}
                        label="Casting Calls"
                        icon={<CastingCallsIcon />}
                        isSelected={isCastingCallsActive}
                      />
                      {isCastingCallsActive && (
                        <SubMenu>
                          <NavSubItem
                            to={castingCallsPath.pattern}
                            label="All"
                          />
                          {/* <NavSubItem
                        to={castingCallsFavoritesPath.pattern}
                        label="Favorites"
                      /> */}
                          {user.canIndexMyCastingCallSubmissions.value && (
                            <NavSubItem
                              to={castingCallsMySubmissionsPath.pattern}
                              label="My Submissions"
                            />
                          )}
                          {user.canIndexMyCastingCalls.value && (
                            <NavSubItem
                              to={castingCallsMyCallsPath.pattern}
                              label="My Casting Calls"
                            />
                          )}
                        </SubMenu>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {user.canIndexMyCastingCalls.value && (
                    <NavItem
                      to={castingCallsMyCallsPath.pattern}
                      label="Casting Calls"
                      icon={<CastingCallsIcon />}
                    />
                  )}
                </>
              )}
              <NavItem
                to={talentAgenciesPath.pattern}
                label="Talent Agencies"
                icon={<TalentAgenciesIcon />}
              />
              {/* <NavItem
                to={marketingAgenciesPath.pattern}
                label="Marketing Agencies"
                icon={<MarketingAgenciesIcon />}
              /> */}
              {/* <NavItem
                to={brandAgenciesPath.pattern}
                label="Brands"
                icon={<BrandsIcon />}
              /> */}
              {user.canIndexMyRoster.value && (
                <NavItem
                  to={myRosterPath.pattern}
                  label="My Roster"
                  icon={<MyRosterIcon />}
                />
              )}
            </ul>
          </li>
        </ul>
        <Hr />
        <h3 className="text-gray50 mb-2 pl-3">PERSONAL SETTINGS</h3>
        <ul className="-mx-2 space-y-1">
          <NavItem
            to={settingsAccountPath({})}
            label="Account"
            icon={<AccountIcon />}
          />
          {user.agencyRole === "AGENCY_ADMIN" && (
            <NavItem
              to={settingsTeamPath({})}
              label="Team"
              icon={<TeamIcon />}
            />
          )}
          {user.agency?.canManageSubscriptions.value ? (
            <NavItem
              to={settingsSubscriptionPath({})}
              label="Subscription"
              icon={<SubscriptionIcon />}
            />
          ) : null}
        </ul>
        {user.isAdmin && (
          <>
            <Hr />
            <h3 className="text-gray50 mb-2 pl-5">ADMIN MENU</h3>
            <ul className="-mx-2 space-y-1">
              <NavItem
                to={adminUsersPath.pattern}
                label="Users"
                icon={<AdminUsersIcon />}
              />
            </ul>
          </>
        )}
      </nav>
    </div>
  )
}

const SubMenu = ({ children }: { children: React.ReactNode }) => {
  return (
    <li>
      <ul className="flex flex-col">{children}</ul>
    </li>
  )
}

const NavItem = ({
  to,
  label,
  icon,
  isSelected,
}: {
  to: string
  label: string
  icon: React.ReactNode
  isSelected?: boolean
}) => {
  const location = useLocation()
  const selected = isSelected || matchPath(location.pathname, to)

  return (
    <li>
      <Link
        to={to}
        className={cn({
          "flex items-center rounded w-full px-4 py-2 my-1 border-l-4 border-white text-hoveredSecondary hover:text-defaultPrimary hover:bg-backgroundPrimary hover:border-defaultPrimary cursor-pointer":
            true,
          "text-defaultPrimary border-l-4 border-defaultPrimary bg-backgroundPrimary":
            selected,
        })}
      >
        <div className="w-6 mr-2 flex justify-center" aria-hidden="true">
          {icon}
        </div>
        <span>{label}</span>
      </Link>
    </li>
  )
}

const NavSubItem = ({ to, label }: { to: string; label: string }) => {
  const location = useLocation()
  const selected = matchPath(location.pathname, to)

  return (
    <li className="flex flex-col">
      <Link
        to={to}
        className={cn("flex flex-col px-4 pl-14 py-2 text-sm cursor-pointer", {
          "font-bold": selected,
        })}
      >
        <span>{label}</span>
      </Link>
    </li>
  )
}
