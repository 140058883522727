import { useNavigate } from "react-router-dom"
import { Button } from "~/shadcn/ui/button"
import Text, { H1 } from "~/ui/typography"
import { SubscriptionPlanSelect } from "./components/SubscriptionPlanSelect"

export const OnboardingChoosePlanScreen = () => {
  const navigate = useNavigate()

  return (
    <div className="flex-1 flex flex-col self-stretch">
      <div className="text-defaultSecondary mb-8">Step 3/3</div>
      <H1 className="mb-2">Choose Your Plan</H1>
      <Text variant="header3">
        Select the plan that best suits your business needs. Each plan includes
        a FREE 30 day trial.
      </Text>
      <hr className="my-9" />
      <SubscriptionPlanSelect />
      <div className="mt-8 self-stretch flex-1 flex-grow flex justify-between items-end">
        <Button type="button" variant="ghost" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
    </div>
  )
}
