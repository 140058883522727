import { zodResolver } from "@hookform/resolvers/zod"
import z from "zod"

const searchSchema = z.object({
  query: z.string().optional(),
})

export type SearchValues = z.infer<typeof searchSchema>
export const searchResolver = zodResolver(searchSchema)

export const searchDefaultValues: SearchValues = {
  query: "",
}
